






















import { Action, Getter } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';
import RedirectableMixin from '@/app/core/mixins/redirectable.mixin';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import AuthModule from '../store';
import SignInDto from '../dto/sign-in.dto';
import SignInForm from '../components/sign-in-form.vue';

@Component({
  components: {
    SignInForm,
  },
  metaInfo(this: SignIn) {
    return { title: this.$t('pageTitle.signIn').toString() };
  },
})
export default class SignIn extends Mixins(RedirectableMixin, HandlesErrorMixin) {
  private loading = false;

  @Getter
  private isAuthenticated!: boolean;

  @Action
  private signIn!: typeof AuthModule.prototype.signIn;

  async onSubmit(dto: SignInDto) {
    this.loading = true;

    try {
      await this.signIn(dto);
      this.$router.push(this.redirect || '/');
    } catch (error) {
      if (this.isForbiddenError(error)) {
        const message = this.$t('error.wrongEmailOrPassword').toString();

        this.$notify.error({
          message,
          options: { lefty: true },
        });
      } else {
        this.handleError(error);
      }
    } finally {
      this.loading = false;
    }
  }

  created() {
    if (this.isAuthenticated) {
      this.$router.push('dashboard');
    }
  }
}
