







































import { Mixins, Component } from 'vue-property-decorator';
import { required } from '@/app/core/validation';
import FormMixin from '@/app/core/mixins/form.mixin';
import SignInDto from '../dto/sign-in.dto';

@Component
export default class SignInForm extends Mixins(FormMixin) {
  private showPassword = false;
  private showConfirmPassword = false;

  protected data: SignInDto = {
    email: '',
    password: '',
  };

  protected rules = {
    email: [required],
    password: [required],
  };
}
