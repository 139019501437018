import isArray from 'lodash/isArray';
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ValidationRulesMixin extends Vue {
  get redirect() {
    const { redirect } = this.$route.query;

    return isArray(redirect) ? redirect[0] : redirect;
  }
}
